import React from 'react'

const Order = (props) => {
    console.log(props);
    return(
        <>
        <ul className={"actions " + (props.fixed ? 'fixedButton ' :'')+( props.value==="floersheim" ? "floersheimOrder" : props.value==="monsheim" ? "monsheimOrder" : "")}>
            <li><div className={"button  special fit order-button" }  data-glf-cuid="67b37277-7a87-4006-87db-3fc1fcbf1894" data-glf-ruid="3fbb2982-32e4-4393-9998-3e6d93235bc0">Karte & Bestellen  <span>von Flörsheim-Dalsheim</span></div></li>
            <li><div className={"button  special fit order-button"} data-glf-cuid="67b37277-7a87-4006-87db-3fc1fcbf1894" data-glf-ruid="a922fbd7-7f49-4e67-82f6-43f9d8e7e7e3">Karte & Bestellen  <span>von Monsheim</span> </div></li>
        </ul>
        {/* <ul className={"actions " + (props.fixed ? 'fixedButton' :'') }>
        </ul> */}
    </>
    )
    
    }


export default Order