import React from 'react'

const Opening = (props) => (
    
    <section id="opening">
        <div className="inner">
            <div>
                <header className="major">
                    <h2>Öffnungszeiten Flörsheim-Dalsheim</h2>
                </header>
                <p>
                Montag : Ruhetag <br />
                Di. - Sa. : 11.00 – 22.00 Uhr <br />
                So. & Feiertag : 12.00 – 22.00 Uhr
                </p>
            </div>
            
            <div>
                <header className="major">
                <h2>Öffnungszeiten Monsheim</h2>
                </header>
                <p>
                Montag : Ruhetag <br />
                Di. - Sa. : 11.00 – 22.00 Uhr <br />
                So. & Feiertag : 12.00 – 22.00 Uhr
                </p>
            </div>
        </div>
    </section>
)

export default Opening



